/* Admin Dashboard Styles */
.admin-dashboard {
  display: flex;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.dashboard-sidebar {
  width: 250px;
  background: #1a1a1a;
  color: #fff;
  padding: 20px 0;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  transition: all 0.3s ease;
  z-index: 999;
}

.sidebar-header {
  padding: 0 25px 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
}

.sidebar-header h2 {
  font-size: 24px;
  margin-bottom: 5px;
  color: #fff;
}

.sidebar-header h2 span {
  color: #ff5f00;
}

.sidebar-header p {
  font-size: 14px;
  opacity: 0.7;
  margin: 0;
}

.sidebar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  display: flex;
  align-items: center;
  padding: 12px 25px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar-menu li:hover, .sidebar-menu li.active {
  background: rgba(255, 95, 0, 0.1);
  border-left: 3px solid #ff5f00;
}

.sidebar-menu li svg {
  margin-right: 10px;
  fill: #fff;
}

.sidebar-menu li.active svg {
  fill: #ff5f00;
}

.sidebar-menu li span {
  font-size: 15px;
}

.sidebar-footer {
  padding: 20px 25px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 20px;
}

.logout-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  padding: 0;
}

.logout-button svg {
  margin-right: 10px;
  fill: #fff;
}

.dashboard-content {
  flex: 1;
  margin-left: 250px;
  padding: 30px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.dashboard-header h1 {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.header-actions {
  display: flex;
  align-items: center;
}

.search-box {
  margin-right: 20px;
}

.admin-form-control {
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 14px;
  width: 250px;
}

.user-profile {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ff5f00;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-right: 10px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.user-role {
  font-size: 12px;
  opacity: 0.7;
  margin: 0;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
}

.stat-icon {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.stat-icon svg {
  fill: #ff5f00;
}

.stat-info h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 5px;
  color: #333;
}

.stat-info p {
  font-size: 14px;
  margin: 0;
  color: #666;
}

.dashboard-main-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.activity-section, .quick-actions-section {
  background: #fff;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-header h2 {
  font-size: 18px;
  margin: 0;
  color: #333;
}

.view-all-btn {
  background: none;
  border: none;
  color: #ff5f00;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
}

.activity-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.activity-item {
  display: flex;
  align-items: flex-start;
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f5;
}

.activity-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.activity-icon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.activity-icon.user {
  background: #4CAF50;
}

.activity-icon.message {
  background: #2196F3;
}

.activity-icon.reference {
  background: #F44336;
}

.activity-icon.blog {
  background: #FF9800;
}

.activity-content h3 {
  font-size: 15px;
  margin: 0 0 5px;
  color: #333;
}

.activity-content p {
  font-size: 14px;
  margin: 0 0 5px;
  color: #666;
}

.activity-time {
  font-size: 12px;
  color: #999;
}

.actions-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.action-button {
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button:hover {
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transform: translateY(-2px);
}

.action-icon {
  width: 45px;
  height: 45px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.action-text h3 {
  font-size: 15px;
  margin: 0 0 5px;
  color: #333;
}

.action-text p {
  font-size: 13px;
  margin: 0;
  color: #666;
}

.empty-state {
  background: #fff;
  border-radius: 8px;
  padding: 50px 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);
  text-align: center;
}

.empty-state svg {
  fill: #ff5f00;
  opacity: 0.5;
  margin-bottom: 20px;
}

.empty-state h2 {
  font-size: 20px;
  margin: 0 0 10px;
  color: #333;
}

.empty-state p {
  font-size: 15px;
  margin: 0;
  color: #666;
}

.dashboard-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #ff5f00;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive styles */
@media (max-width: 992px) {
  .stats-container {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .dashboard-main-content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .dashboard-sidebar {
    width: 70px;
    padding: 15px 0;
  }
  
  .sidebar-header {
    padding: 0 15px 15px;
    text-align: center;
  }
  
  .sidebar-header h2 {
    font-size: 20px;
  }
  
  .sidebar-header p {
    display: none;
  }
  
  .sidebar-menu li {
    padding: 12px 0;
    justify-content: center;
  }
  
  .sidebar-menu li span {
    display: none;
  }
  
  .sidebar-menu li svg {
    margin-right: 0;
  }
  
  .sidebar-footer {
    padding: 15px;
    text-align: center;
  }
  
  .logout-button {
    justify-content: center;
  }
  
  .logout-button span {
    display: none;
  }
  
  .logout-button svg {
    margin-right: 0;
  }
  
  .dashboard-content {
    margin-left: 70px;
    padding: 20px;
  }
  
  .stats-container {
    grid-template-columns: 1fr;
  }
}

.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
}

/* Ana içerik stili */
.content {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0;
  margin: 0 auto;
}

/* Hero section stili */
.hero {
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  background: linear-gradient(135deg, #1a1a1a 0%, #111111 100%);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  color: #fff;
}

/* Hero arka plan animasyonları */
.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255, 95, 0, 0.1) 0%, rgba(255, 136, 0, 0.15) 100%);
}

.circle-1 {
  width: 300px;
  height: 300px;
  top: -50px;
  right: -50px;
  animation: float 20s infinite alternate ease-in-out;
}

.circle-2 {
  width: 200px;
  height: 200px;
  bottom: 10%;
  left: 5%;
  animation: float 15s infinite alternate-reverse ease-in-out;
}

.circle-3 {
  width: 400px;
  height: 400px;
  bottom: -100px;
  right: 20%;
  animation: float 25s infinite alternate ease-in-out;
}

.circle-4 {
  width: 150px;
  height: 150px;
  top: 20%;
  left: 20%;
  animation: float 18s infinite alternate-reverse ease-in-out;
}

.circle-5 {
  width: 100px;
  height: 100px;
  top: 40%;
  right: 10%;
  animation: float 12s infinite alternate ease-in-out;
}

.light {
  position: absolute;
  filter: blur(80px);
  opacity: 0.3;
  border-radius: 50%;
}

.light-1 {
  width: 500px;
  height: 500px;
  background: radial-gradient(circle, rgba(255, 95, 0, 0.3) 0%, rgba(255, 95, 0, 0) 70%);
  top: -200px;
  right: -100px;
  animation: pulse 8s infinite alternate ease-in-out;
}

.light-2 {
  width: 600px;
  height: 600px;
  background: radial-gradient(circle, rgba(255, 95, 0, 0.25) 0%, rgba(255, 95, 0, 0) 70%);
  bottom: -200px;
  left: -100px;
  animation: pulse 10s infinite alternate-reverse ease-in-out;
}

/* Ana hero içerik konteyneri */
.hero-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  z-index: 2;
  padding: 0 20px;
}

/* Hero metin bölümü */
.hero-text {
  text-align: left;
  max-width: 600px;
  width: 100%;
  animation: slideInLeft 1s ease;
}

.subtitle {
  font-size: 1.2rem;
  color: #ff7a30;
  margin-bottom: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.title {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  background: linear-gradient(90deg, #ffffff 0%, #ff5f00 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.separator {
  display: flex;
  align-items: center;
  margin: 25px 0;
}

.separator span {
  height: 2px;
  width: 80px;
  background-color: #ff5f00;
}

.separator .dot {
  width: 10px;
  height: 10px;
  background-color: #ff5f00;
  border-radius: 50%;
  margin: 0 15px;
  display: block;
}

.description {
  font-size: 1.2rem;
  line-height: 1.7;
  margin-bottom: 30px;
  color: #cccccc;
}

/* Hero kart bölümü */
.hero-card {
  background: #222222;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: slideInRight 1s ease;
  border: 1px solid rgba(255, 95, 0, 0.2);
}

.hero-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 30px 60px rgba(255, 95, 0, 0.2);
  border: 1px solid rgba(255, 95, 0, 0.4);
}

.card-content {
  padding: 40px;
  text-align: center;
}

.card-icon {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.hero-card h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #ff7a30;
}

.hero-card p {
  font-size: 1rem;
  line-height: 1.6;
  color: #cccccc;
  margin-bottom: 25px;
}

.card-button {
  display: inline-block;
  padding: 12px 25px;
  background: transparent;
  border: 2px solid #ff5f00;
  color: #ff5f00;
  font-weight: 600;
  border-radius: 30px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.card-button:hover {
  background: #ff5f00;
  color: white;
}

/* Hero butonlar */
.hero-buttons {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.hero-button {
  padding: 15px 30px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.primary-button {
  background: linear-gradient(90deg, #ff5f00 0%, #ff8800 100%);
  border: none;
  color: white;
  box-shadow: 0 10px 20px rgba(255, 95, 0, 0.2);
}

.primary-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 15px 30px rgba(255, 95, 0, 0.3);
}

.primary-button span {
  z-index: 1;
}

.btn-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.primary-button:hover .btn-arrow {
  transform: translateX(5px);
}

.secondary-button {
  background: transparent;
  border: 2px solid #ff5f00;
  color: #ff5f00;
}

.secondary-button:hover {
  background: rgba(255, 95, 0, 0.1);
  transform: translateY(-3px);
}

/* Kaydırma göstergesi */
.scroll-indicator {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: fadeIn 1s ease 1.5s forwards;
  z-index: 2;
  width: auto;
  margin: 0 auto;
}

.mouse {
  width: 30px;
  height: 50px;
  border: 2px solid #aaa;
  border-radius: 20px;
  position: relative;
  margin-bottom: 10px;
}

.wheel {
  width: 5px;
  height: 10px;
  background-color: #aaa;
  border-radius: 3px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  animation: scrollWheel 2s ease infinite;
}

.scroll-indicator p {
  font-size: 0.9rem;
  color: #aaa;
  font-weight: 500;
}

/* Animasyonlar */
@keyframes scrollWheel {
  0% {
    opacity: 1;
    transform: translateY(0) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translateY(15px) rotate(0deg);
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(5deg);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.2;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(1.05);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive ayarları */
@media screen and (max-width: 1024px) {
  .hero-container {
    flex-direction: column;
    gap: 40px;
  }
  
  .hero-text {
    text-align: center;
    padding: 0 20px;
  }
  
  .separator {
    justify-content: center;
  }
  
  .hero-buttons {
    justify-content: center;
  }
  
  .title {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 2.8rem;
  }
  
  .description {
    font-size: 1rem;
  }
  
  .hero-card {
    max-width: 100%;
  }
  
  .hero-buttons {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin: 30px auto 0;
  }
  
  .hero-button {
    width: 100%;
  }
  
  .scroll-indicator {
    z-index: 1;

    left: 0;
    right: 0;
    bottom: 30px;
    margin: 0 auto;
    width: 100%;
    transform: none;
    display: flex;
    justify-content: center;
  }
}

/* Kontak Formu Kartı */
.contact-form-card {
  background-color: #232323;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  width: 400px;
  max-width: 100%;
  z-index: 1030;
  animation: slideInRight 1s ease;
  border: 1px solid rgba(255, 95, 0, 0.2);
  padding: 22px;
  display: flex;
  flex-direction: column;
}

.contact-form-card form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.form-group label {
  color: #ccc;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: left;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group textarea {
  padding: 8px 10px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  font-size: 0.9rem;
  color: white;
  transition: all 0.3s ease;
}

.form-group textarea {
  min-height: 50px;
  resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #ff5f00;
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 95, 0, 0.2);
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

/* Range slider temel stiller */
.form-group input[type="range"].custom-range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  outline: none;
  margin: 10px 0;
  background: linear-gradient(to right, #ff5f00 0%, rgba(255, 255, 255, 0.2) 0%);
}

/* Chrome, Safari, Edge, Opera için */
.form-group input[type="range"].custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%; 
  background: #ff5f00;
  border: 2px solid white;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

/* Firefox için */
.form-group input[type="range"].custom-range::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ff5f00;
  border: 2px solid white;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

/* Form Gönder Buton Stili */
.form-group .submit-btn {
  width: 100%;
  padding: 12px 20px;
  background-color: #ff5f00;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-group .submit-btn:hover {
  background-color: #e05500;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 95, 0, 0.3);
}

.form-group .submit-btn:active {
  transform: translateY(0);
}

.budget-group {
  margin: 3px 0;
}

.budget-slider {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 3px;
}

.budget-display {
  color: #ff5f00;
  font-weight: 600;
  margin-left: 5px;
}

@media screen and (min-width: 769px) {
  .contact-form-card {
    padding: 20px;
  }
  
  .form-group .submit-btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1024px) {
  .contact-form-card {
    max-width: 600px;
  }
}

@media screen and (max-width: 768px) {
  .contact-form-card {
    z-index: 1200;
    max-width: 100%;
  }
}

.hero-button.secondary-button {
  background-color: transparent;
  border: 2px solid #ff5f00;
  color: #ff5f00;
}

.hero-button.secondary-button:hover {
  background-color: #ff5f00;
  color: white;
}

.mobile-quote-btn {
  display: none;
}

.mobile-form-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.05);
}

.mobile-form-card {
  background-color: #232323;
  border-radius: 10px;
  border: 1px solid rgba(255, 95, 0, 0.2);
  width: 90%;
  max-width: 450px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 25px 20px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.mobile-form-card::-webkit-scrollbar {
  width: 6px;
}

.mobile-form-card::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
}

.mobile-form-card::-webkit-scrollbar-thumb {
  background-color: rgba(255, 95, 0, 0.4);
  border-radius: 3px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form kapatma arka planı */
.modal-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  animation: fadeIn 0.3s ease;
}

.modal-backdrop.active {
  display: block;
}

/* Kapatma butonu */
.mobile-form-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 95, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  color: #ff5f00;
  border: none;
  z-index: 1101;
  line-height: 1;
  transition: background-color 0.2s ease;
}

.mobile-form-close:hover {
  background-color: rgba(255, 95, 0, 0.2);
}

.budget-display {
  display: inline-block;
  min-width: 80px;
  text-align: right;
  font-weight: 600;
  color: #ff5f00;
}

@media screen and (max-width: 768px) {
  .mobile-quote-btn {
    display: flex;
  }
  
  .mobile-form-card {
    padding: 30px 20px;
  }
  
  .mobile-form-card .form-group {
    margin-bottom: 15px;
    gap: 5px;
  }
  
  /* Range slider için özel mobil ayarlar */
  .mobile-form-card input[type="range"].custom-range {
    margin: 10px 0;
  }
  
  .mobile-form-card input[type="range"].custom-range::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    margin-left: -2px;
  }
  
  .mobile-form-card input[type="range"].custom-range::-moz-range-thumb {
    width: 20px;
    height: 20px;
    margin-left: -2px;
  }
  
  /* Formun içindeki bütün input ve textarea boyutlarını küçült */
  .mobile-form-card input,
  .mobile-form-card textarea {
    padding: 10px 12px;
    font-size: 14px;
  }
  
  /* Textarea'yı daha küçük yap */
  .mobile-form-card textarea {
    max-height: 100px;
  }
}

/* Range input stil düzeltmeleri */
input[type="range"].custom-range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background: linear-gradient(to right, #ff5f00 0%, rgba(255, 255, 255, 0.2) 0%);
  outline: none;
  transition: background 0.2s;
}

input[type="range"].custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ff5f00;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

input[type="range"].custom-range::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ff5f00;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Loading ekranı için stil */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 18px;
  color: #ff5f00;
  position: relative;
}

.loading:after {
  content: "";
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #ff5f00;
  border-radius: 50%;
  margin-left: 15px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Modern Inbox Manager Styles */
.modern-inbox-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  background: #f8f8f8;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.inbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  background: #fff;
  border-bottom: 1px solid #eaeaea;
}

.inbox-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #333;
}

.refresh-button {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: none;
  color: #555;
  font-size: 14px;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.refresh-button:hover {
  background: #ebebeb;
}

.refresh-button svg {
  margin-right: 8px;
}

.inbox-content-wrapper {
  display: flex;
  height: 100%;
  background: #fff;
}

.message-list {
  width: 35%;
  border-right: 1px solid #eaeaea;
  overflow-y: auto;
  background: #f8f8f8;
}

.message-card {
  padding: 16px;
  margin: 10px;
  border-radius: 12px;
  background: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
}

.message-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

.message-card.unread {
  background: #fff5f0;
}

.message-card.selected {
  background: #fff5f0;
  border-left: 3px solid #ff5f00;
}

.message-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

.sender-name {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.message-date {
  font-size: 12px;
  color: #999;
}

.message-info {
  margin-top: 5px;
}

.message-contact {
  font-size: 13px;
  color: #666;
  margin: 0 0 6px;
}

.message-preview {
  font-size: 13px;
  color: #777;
  margin: 0;
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.unread-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ff5f00;
}

.message-details-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0;
  background: #fff;
}

.message-details {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.message-details-header {
  padding: 25px 30px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.message-sender-info h2 {
  font-size: 22px;
  margin: 0 0 15px;
  color: #333;
}

.message-contact-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-item {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
}

.contact-item svg {
  margin-right: 8px;
}

.delete-button {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 10px 16px;
  color: #ff5f00;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-button:hover {
  background: #fff5f0;
  border-color: #ffcfb8;
}

.delete-button svg {
  margin-right: 8px;
}

.message-content {
  padding: 25px 30px;
  flex: 1;
}

.message-content h3 {
  font-size: 18px;
  margin: 0 0 15px;
  color: #333;
}

.message-text {
  font-size: 15px;
  line-height: 1.6;
  color: #444;
  white-space: pre-line;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.empty-inbox, .no-message-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 40px;
  text-align: center;
  background: #fff;
}

.empty-icon {
  margin-bottom: 20px;
  background: #f9f9f9;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-inbox h3 {
  font-size: 20px;
  margin: 0 0 10px;
  color: #333;
}

.empty-inbox p, .no-message-selected p {
  font-size: 15px;
  color: #777;
  margin: 0;
}

.inbox-loading, .inbox-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  background: #fff;
  border-radius: 12px;
}

.inbox-error button {
  margin-top: 20px;
  background: #ff5f00;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.inbox-error button:hover {
  background: #e55500;
}

@media (max-width: 992px) {
  .inbox-content-wrapper {
    flex-direction: column;
  }
  
  .message-list {
    width: 100%;
    height: 40%;
    border-right: none;
    border-bottom: 1px solid #eaeaea;
  }
  
  .message-details-panel {
    height: 60%;
  }
  
  .message-details-header {
    padding: 15px 20px;
  }
  
  .message-content {
    padding: 15px 20px;
  }
}