.footer {
  background-color: #222;
  color: #fff;
  padding: 40px 0 20px;
  font-family: 'Poppins', sans-serif;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-bottom: 30px;
}

/* Logo ve şirket bilgileri */
.footer-column:first-child {
  text-align: left;
}

.footer-logo {
  margin-bottom: 15px;
  text-align: left;
}

.footer-logo h3 {
  font-size: 26px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  text-align: left;
}

.footer-logo span {
  color: #ff5f00;
}

.footer-description {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #ccc;
  max-width: 260px;
  text-align: left;
}

.footer-contact-info {
  margin-bottom: 15px;
  text-align: left;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
}

.contact-item svg {
  fill: #ff5f00;
  margin-right: 10px;
  min-width: 20px;
}

.contact-item a,
.contact-item address {
  color: #ccc;
  text-decoration: none;
  font-style: normal;
  font-size: 14px;
  transition: color 0.3s;
}

.contact-item a:hover {
  color: #ff5f00;
}

/* Başlıklar ve linkler */
.footer-heading {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;
  color: #fff;
}

.footer-heading::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  background-color: #ff5f00;
}

/* Bülten ve sosyal medya */
.newsletter-text {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 15px;
  line-height: 1.6;
}

.newsletter-form {
  display: flex;
  margin-bottom: 20px;
}

.newsletter-form input {
  flex: 1;
  padding: 8px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: #fff;
  border-radius: 4px 0 0 4px;
  font-size: 14px;
}

.newsletter-form input:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.15);
}

.newsletter-form button {
  background-color: #ff5f00;
  border: none;
  color: white;
  padding: 0 15px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter-form button:hover {
  background-color: #e55400;
}

.newsletter-form button svg {
  fill: white;
}

/* Alt kısım - telif hakkı */
.footer-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
}

.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: #999;
}

.copyright-text {
  text-align: left;
  margin: 0;
}

.copyright-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transition: all 0.3s;
}

.social-links a:hover {
  background-color: #ff5f00;
}

.social-links svg {
  fill: #fff;
  width: 16px;
  height: 16px;
}

.footer-bottom-links {
  display: flex;
  align-items: center;
}

.footer-bottom-links a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 13px;
  white-space: nowrap;
}

.footer-bottom-links a:hover {
  color: #ff5f00;
}

.separator {
  margin: 0 10px;
  color: #666;
}

.legal-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
}

.legal-links a {
  color: #ddd;
  text-decoration: none;
  transition: color 0.3s ease;
  margin: 0 5px;
}

.legal-links a:hover {
  color: #ff5f00;
}

.footer-legal {
  display: none;
}

/* Responsive */
@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .copyright {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }
  
  .copyright-text {
    text-align: center;
  }
  
  .copyright-right {
    flex-direction: column;
    gap: 15px;
  }
  
  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-column:first-child {
    align-items: flex-start;
  }
  
  .footer-column .footer-heading::after {
    left: 0;
    transform: none;
  }
  
  .footer-description {
    max-width: 100%;
  }
  
  .newsletter-form {
    width: 100%;
  }
  
  .footer-links-container {
    flex-direction: column;
    gap: 15px;
  }
}
