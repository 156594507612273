.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 85px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  z-index: 20;
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar.scrolled .nav-link {
  color: white;
}

.navbar.scrolled .nav-link:hover {
  color: #ff5f00;
}

.navbar.scrolled .nav-link.cta {
  background-color: #ff5f00;
  color: white;
  border-color: #ff5f00;
}

.navbar.scrolled .nav-link.cta:hover {
  background-color: transparent;
  color: #ff5f00;
  border-color: #ff5f00;
}

.navbar.scrolled .mobile-menu-btn span {
  background-color: #ff5f00;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  position: relative;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.logo {
  height: 75px;
  width: auto;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.navbar-menu {
  display: flex;
}

.navbar-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.navbar-menu li {
  margin-left: 30px;
  position: relative;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 8px 0;
  position: relative;
  transition: all 0.3s ease;
}

.nav-link:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ff5f00;
  transition: width 0.3s ease;
}

.nav-link:hover {
  color: #ff5f00;
}

.nav-link:hover:before {
  width: 100%;
}

.nav-link.cta {
  background-color: #ff5f00;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  transition: all 0.3s ease;
  border: 1px solid #ff5f00;
}

.nav-link.cta:hover {
  background-color: transparent;
  color: #ff5f00;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 95, 0, 0.3);
}

.mobile-menu-btn {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
  z-index: 1001;
}

.mobile-menu-btn span {
  width: 100%;
  height: 3px;
  background-color: #ff5f00;
  border-radius: 3px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
  .navbar-container {
    justify-content: center;
  }
  
  .mobile-menu-btn {
    display: none;
  }

  .navbar-menu {
    display: none;
  }
  
  .navbar-logo {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
