.blog-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.blog-header {
  background-color: #151515;
  color: #fff;
  padding: 80px 20px;
  text-align: center;
}

.blog-header-content {
  max-width: 800px;
  margin: 0 auto;
}

.blog-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.blog-header p {
  font-size: 1.2rem;
  opacity: 0.8;
}

.blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  flex-grow: 1;
}

/* Filtreler */
.blog-filters {
  margin-bottom: 40px;
}

.category-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.category-button {
  padding: 8px 20px;
  border: 2px solid #f0f0f0;
  background: transparent;
  color: #555;
  font-weight: 500;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-button:hover {
  border-color: #ff5f00;
  color: #ff5f00;
}

.category-button.active {
  background-color: #ff5f00;
  color: white;
  border-color: #ff5f00;
}

/* Blog Kartları */
.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.blog-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.blog-card-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.blog-card-image {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.blog-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.blog-card:hover .blog-card-image img {
  transform: scale(1.05);
}

.placeholder-image {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-category {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #ff5f00;
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
}

.blog-card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.blog-card-title {
  margin: 0 0 15px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  line-height: 1.4;
}

.blog-card-excerpt {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
  flex-grow: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.blog-date {
  font-size: 0.85rem;
  color: #888;
}

.read-more {
  color: #ff5f00;
  font-weight: 500;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.blog-card:hover .read-more {
  color: #e85500;
}

/* Paginasyon */
.blog-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 50px;
}

.pagination-button,
.pagination-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid #f0f0f0;
  background: transparent;
  color: #555;
  font-weight: 500;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-button {
  width: 40px;
  height: 40px;
}

.pagination-button svg {
  width: 20px;
  height: 20px;
}

.pagination-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.pagination-number:hover,
.pagination-button:hover:not(:disabled) {
  border-color: #ff5f00;
  color: #ff5f00;
}

.pagination-number.active {
  background-color: #ff5f00;
  color: white;
  border-color: #ff5f00;
}

/* Yükleniyor */
.blog-loading {
  text-align: center;
  padding: 60px 0;
  color: #666;
}

.spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 95, 0, 0.3);
  border-radius: 50%;
  border-top-color: #ff5f00;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.no-posts {
  text-align: center;
  padding: 60px 0;
  color: #666;
  font-size: 1.2rem;
}

/* Responsive Tasarım */
@media (max-width: 1024px) {
  .blog-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .blog-header {
    padding: 60px 20px;
  }
  
  .blog-header h1 {
    font-size: 2.8rem;
  }
  
  .blog-container {
    padding: 40px 20px;
  }
  
  .category-filter {
    overflow-x: auto;
    padding-bottom: 10px;
    justify-content: flex-start;
  }
  
  .blog-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 15px;
  }
}

@media (max-width: 576px) {
  .blog-header h1 {
    font-size: 2.2rem;
  }
  
  .blog-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .blog-pagination {
    flex-wrap: wrap;
  }
}
