.admin-dashboard {
  display: flex;
  width: 100%;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

/* Sidebar Stilleri */
.dashboard-sidebar {
  width: 250px;
  background-color: #222;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
}

.sidebar-header {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.sidebar-header h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.sidebar-header h2 span {
  color: #ff5f00;
}

.sidebar-header p {
  color: #aaa;
  font-size: 0.8rem;
  margin-top: 0.3rem;
}

.sidebar-menu {
  flex-grow: 1;
  padding: 1.5rem 0;
}

.sidebar-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  color: #bbb;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar-menu li:hover {
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.sidebar-menu li.active {
  background-color: rgba(255, 95, 0, 0.15);
  color: #ff5f00;
  border-left: 3px solid #ff5f00;
}

.sidebar-menu li svg {
  margin-right: 0.8rem;
  fill: currentColor;
}

.sidebar-footer {
  padding: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.logout-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px;
  color: #fff;
  padding: 0.7rem;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.logout-button svg {
  margin-right: 0.5rem;
  fill: currentColor;
}

/* Dashboard İçerik Alanı */
.dashboard-content {
  flex-grow: 1;
  margin-left: 250px;
  padding: 2rem;
  background-color: #f5f5f5;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.dashboard-header h1 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.user-profile {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff5f00;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-right: 1rem;
}

.user-info p {
  margin: 0;
}

.user-name {
  font-weight: 500;
  font-size: 0.9rem;
  color: #333;
}

.user-role {
  font-size: 0.8rem;
  color: #888;
}

/* İstatistik Kartları */
.dashboard-stats {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  display: flex;
  align-items: center;
}

.stat-icon {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.stat-icon svg {
  fill: white;
}

.stat-icon.visitors {
  background-color: #4a6bfd;
}

.stat-icon.projects {
  background-color: #ff5f00;
}

.stat-icon.tasks {
  background-color: #00c48c;
}

.stat-icon.messages {
  background-color: #ff6b6b;
}

.stat-info h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #333;
  margin: 0;
  margin-bottom: 0.3rem;
}

.stat-info p {
  font-size: 0.9rem;
  color: #888;
  margin: 0;
}

/* Aktivite Listesi */
.dashboard-activity {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
}

.activity-header {
  margin-bottom: 1.5rem;
}

.activity-header h2 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.activity-list {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.activity-item {
  display: flex;
  align-items: flex-start;
  padding-bottom: 1.2rem;
  border-bottom: 1px solid #eee;
}

.activity-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.activity-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.activity-icon svg {
  fill: white;
}

.activity-icon.new-project {
  background-color: #ff5f00;
}

.activity-icon.message {
  background-color: #4a6bfd;
}

.activity-icon.task {
  background-color: #00c48c;
}

.activity-content h4 {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin: 0;
  margin-bottom: 0.3rem;
}

.activity-content p {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
  margin-bottom: 0.3rem;
}

.activity-time {
  font-size: 0.8rem;
  color: #888;
}

/* Loading ekranı */
.dashboard-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #ff5f00;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.dashboard-loading p {
  color: #666;
  font-size: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive tasarım */
@media (max-width: 768px) {
  .dashboard-sidebar {
    width: 70px;
  }
  
  .sidebar-header h2, .sidebar-header p,
  .sidebar-menu li span, .logout-button span {
    display: none;
  }
  
  .sidebar-menu li {
    justify-content: center;
    padding: 1rem;
  }
  
  .sidebar-menu li svg {
    margin-right: 0;
  }
  
  .logout-button {
    justify-content: center;
  }
  
  .logout-button svg {
    margin-right: 0;
  }
  
  .dashboard-content {
    margin-left: 70px;
    padding: 1.5rem;
  }
}

@media (max-width: 576px) {
  .dashboard-content {
    margin-left: 0;
    padding: 1rem;
  }
  
  .dashboard-sidebar {
    width: 0;
    display: none;
  }
  
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .dashboard-stats {
    grid-template-columns: 1fr;
  }
}

/* Form elemanları ve arama kutuları için stiller */
.admin-form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.admin-form-control:focus {
  border-color: #ff5f00;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(255, 95, 0, 0.1);
  color: #333;
}

/* Placeholder stili */
.admin-form-control::placeholder {
  color: #aaa;
  font-weight: 400;
  font-size: 0.9rem;
  opacity: 0.8;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.admin-form-control:focus::placeholder {
  opacity: 0.6;
  transform: translateX(3px);
}

/* Arama kutusu özel stili */
.search-box {
  position: relative;
  margin-bottom: 1.5rem;
}

.search-box .admin-form-control {
  padding-left: 2.8rem;
}

.search-box::before {
  content: "";
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23aaa'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}

/* Select element stili */
.admin-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23aaa'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.2rem;
  padding-right: 2.5rem;
}

/* Checkbox ve radio stili */
.admin-checkbox,
.admin-radio {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.admin-checkbox input[type="checkbox"],
.admin-radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  display: inline-block;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  flex-shrink: 0;
}

.admin-radio .checkmark {
  border-radius: 50%;
}

.admin-checkbox input:checked ~ .checkmark,
.admin-radio input:checked ~ .checkmark {
  background-color: #ff5f00;
  border-color: #ff5f00;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.admin-checkbox input:checked ~ .checkmark:after,
.admin-radio input:checked ~ .checkmark:after {
  display: block;
}

.admin-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.admin-radio .checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* Form grupları stili */
.admin-form-group {
  margin-bottom: 1.5rem;
}

.admin-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #555;
  font-size: 0.9rem;
}

/* Form elemanları için yardımcı metin */
.form-helper-text {
  margin-top: 0.3rem;
  font-size: 0.8rem;
  color: #888;
}

.form-helper-text.error {
  color: #e74c3c;
}

/* Form düğmeleri stili */
.admin-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.7rem 1.5rem;
  font-size: 0.95rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
}

.admin-btn-primary {
  color: #fff;
  background-color: #ff5f00;
  border-color: #ff5f00;
}

.admin-btn-primary:hover {
  background-color: #e55400;
  border-color: #e55400;
}

.admin-btn-secondary {
  color: #fff;
  background-color: #666;
  border-color: #666;
}

.admin-btn-secondary:hover {
  background-color: #555;
  border-color: #555;
}

.admin-btn-outline {
  color: #ff5f00;
  background-color: transparent;
  border-color: #ff5f00;
}

.admin-btn-outline:hover {
  color: #fff;
  background-color: #ff5f00;
  border-color: #ff5f00;
}

/* Admin menü öğelerine hover ve tıklama özelliği ekleme */
.sidebar-menu ul li {
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar-menu ul li:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Admin Bölümleri Ortak Stiller */
.admin-section {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.section-header h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.add-button {
  display: flex;
  align-items: center;
  background-color: #ff5f00;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-button svg {
  margin-right: 8px;
  fill: currentColor;
}

.add-button:hover {
  background-color: #e55400;
}

/* Referanslar Bölümü */
.reference-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.reference-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.reference-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.reference-image {
  height: 160px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #f7f7f7;
}

.reference-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10px;
}

.reference-info {
  padding: 15px;
}

.reference-info h3 {
  font-size: 16px;
  margin: 0 0 5px;
  color: #333;
}

.reference-info p {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.reference-actions {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 15px;
}

.edit-button, .delete-button, .view-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.edit-button svg, .view-button svg, .delete-button svg {
  fill: #666;
  transition: fill 0.3s;
}

.edit-button:hover {
  background-color: #e8f4fe;
}

.edit-button:hover svg {
  fill: #1976d2;
}

.view-button:hover {
  background-color: #e8f7ed;
}

.view-button:hover svg {
  fill: #388e3c;
}

.delete-button:hover {
  background-color: #fee8e8;
}

.delete-button:hover svg {
  fill: #d32f2f;
}

/* Görsel sayısı rozeti */
.image-count-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff5f00;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  border: 2px solid white;
}

.reference-image {
  position: relative;
}

.reference-card.black-bg .image-count-badge {
  background-color: #ff5f00;
  border-color: #222;
}

.reference-card.white-bg .image-count-badge {
  background-color: #ff5f00;
  border-color: white;
}

/* Önizleme görselleri taşma kontrolleri */
.image-preview {
  overflow-y: auto;
  max-height: 300px;
}

/* Toplu yükleme sırasında ilerleme çubuğunu daha belirgin yap */
.progress-container {
  margin: 15px 0;
}

.progress-text {
  font-weight: 500;
  color: #ff5f00;
}

/* Blog Bölümü */
.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.blog-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.blog-image {
  height: 180px;
  overflow: hidden;
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-info {
  padding: 15px;
}

.blog-info h3 {
  font-size: 18px;
  margin: 0 0 10px;
  color: #333;
}

.blog-info p {
  font-size: 14px;
  color: #666;
  margin: 0 0 5px;
}

.blog-date {
  color: #999 !important;
  font-size: 12px !important;
}

.blog-actions {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 15px;
}

/* Gelen Mailler Bölümü */
.inbox-filter {
  display: flex;
  align-items: center;
}

.filter-select {
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.mail-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mail-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 1fr;
  gap: 15px;
  align-items: center;
  transition: background-color 0.3s;
}

.mail-item.unread {
  background-color: #f8f9ff;
  border-left: 3px solid #1976d2;
}

.mail-sender h3 {
  font-size: 16px;
  margin: 0 0 5px;
  color: #333;
}

.mail-date {
  font-size: 12px;
  color: #999;
  margin: 0;
}

.mail-subject p {
  font-size: 14px;
  color: #333;
  margin: 0;
  font-weight: 500;
}

.mail-preview p {
  font-size: 14px;
  color: #666;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail-actions {
  display: flex;
  justify-content: flex-end;
}

/* Responsive düzenlemeler */
@media (max-width: 1024px) {
  .reference-list, .blog-list {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
  
  .mail-item {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .mail-actions {
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .section-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .add-button {
    width: 100%;
    justify-content: center;
  }
  
  .inbox-filter {
    width: 100%;
  }
  
  .filter-select {
    width: 100%;
  }
}

/* Placeholder içeriği */
.content-placeholder {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 50px 20px;
  text-align: center;
  margin-top: 30px;
}

.content-placeholder p {
  color: #666;
  font-size: 16px;
}

/* ReferenceManager Stilleri */
.reference-manager {
  width: 100%;
}

.reference-form {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.form-group small {
  display: block;
  color: #666;
  font-size: 12px;
  margin-top: 5px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-label input {
  margin-right: 10px;
}

.file-input {
  width: 100%;
  padding: 8px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.progress-bar {
  height: 6px;
  background-color: #eee;
  border-radius: 3px;
  margin-top: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #ff5f00;
  transition: width 0.3s ease;
}

.current-image {
  margin-top: 15px;
}

.preview-image {
  max-width: 200px;
  max-height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  background-color: #fff;
}

.form-buttons {
  display: flex;
  gap: 10px;
}

.save-button, .cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.save-button {
  background-color: #ff5f00;
  color: white;
}

.save-button:hover {
  background-color: #e55400;
}

.save-button:disabled {
  background-color: #ffaa7a;
  cursor: not-allowed;
}

.cancel-button {
  background-color: #f2f2f2;
  color: #333;
}

.cancel-button:hover {
  background-color: #e6e6e6;
}

.error-message {
  background-color: #fff4f4;
  color: #d32f2f;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
  border-left: 4px solid #d32f2f;
}

.loading, .no-data {
  padding: 40px 0;
  text-align: center;
  color: #666;
}

.mt-4 {
  margin-top: 30px;
}

/* Responsive düzenlemeler */
@media (max-width: 768px) {
  .reference-form {
    padding: 15px;
  }
  
  .form-buttons {
    flex-direction: column;
  }
  
  .save-button, .cancel-button {
    width: 100%;
  }
}

/* Başarı mesajı stili */
.success-message {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
  border-left: 4px solid #2e7d32;
  display: flex;
  align-items: center;
}

.success-message svg {
  margin-right: 10px;
  fill: #2e7d32;
}

/* Form satırı stili */
.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.form-row .form-group {
  flex: 1;
  min-width: 250px;
}

/* Renk seçim alanı */
.color-options {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.color-option {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.color-option.active {
  border-color: #ff5f00;
  background-color: #fff8f5;
}

.color-option input[type="radio"] {
  display: none;
}

.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
  border: 1px solid #ddd;
}

.color-preview.black {
  background-color: #000;
}

.color-preview.white {
  background-color: #fff;
}

/* Görsel önizleme alanı */
.image-preview {
  width: 100%;
  min-height: 120px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #f9f9f9;
}

.no-image {
  color: #999;
  font-style: italic;
}

/* İlerleme çubuğu */
.progress-container {
  margin-top: 8px;
}

.progress-text {
  display: block;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

/* Referans kartları */
.reference-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.reference-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
}

.reference-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.15);
}

.reference-card.white-bg {
  background-color: white;
  border: 1px solid #eee;
}

.reference-card.black-bg {
  background-color: black;
  color: white;
}

.reference-image {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  overflow: hidden;
}

.reference-image img {
  max-width: 100%;
  max-height: 130px;
  object-fit: contain;
}

.reference-info {
  padding: 15px;
  background-color: #f9f9f9;
}

.reference-card.black-bg .reference-info {
  background-color: #222;
}

.reference-detail {
  margin-bottom: 8px;
  font-size: 14px;
}

.reference-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 15px;
  background-color: #f2f2f2;
  border-top: 1px solid #eee;
}

.reference-card.black-bg .reference-actions {
  background-color: #333;
  border-color: #444;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #ff5f00;
  animation: spin 1s linear infinite;
  margin: 0 auto 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-data {
  text-align: center;
  padding: 30px 0;
  color: #666;
}

.no-data svg {
  margin-bottom: 15px;
  fill: #ddd;
}

.no-data-hint {
  font-size: 14px;
  color: #999;
  margin-top: 5px;
}

.section-subtitle {
  color: #666;
  font-size: 14px;
  margin-top: 5px;
}

/* Dosya yükleme alanı */
.file-upload-container {
  position: relative;
  margin-bottom: 10px;
}

.file-upload-label {
  display: inline-flex;
  align-items: center;
  padding: 8px 15px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.file-upload-label:hover {
  background-color: #e6e6e6;
}

.file-upload-label svg {
  margin-right: 8px;
  fill: #333;
}

.file-name {
  margin-left: 10px;
  font-size: 14px;
  color: #666;
}

/* Toplu İşlem Stilleri */
.bulk-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.bulk-action-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 7px 12px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.bulk-action-button.active {
  background-color: #ff5f00;
  color: white;
  border-color: #e85600;
}

.bulk-action-button.active svg {
  fill: white;
}

.bulk-action-button svg {
  fill: #555;
}

.selection-info {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
}

.bulk-action-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  width: 100%;
}

.bulk-action-controls button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 7px 12px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
}

.bulk-action-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.bulk-action-controls button:not(:disabled):hover {
  background-color: #e8e8e8;
}

.bulk-select-all {
  color: #333;
}

.bulk-white-bg {
  color: #333;
}

.bulk-black-bg {
  color: #333;
}

.bulk-delete {
  color: #d32f2f;
}

.bulk-delete svg {
  fill: #d32f2f;
}

.bulk-delete:not(:disabled):hover {
  background-color: #ffebee !important;
}

/* Seçim Onay Kutusu */
.select-checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
}

.select-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.select-checkbox label {
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  background-color: rgba(255,255,255,0.8);
  transition: all 0.2s;
}

.select-checkbox input[type="checkbox"]:checked + label {
  background-color: #ff5f00;
  border-color: #ff5f00;
}

.select-checkbox input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 10px;
  height: 5px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(-45deg);
}

/* Seçili Kart Stili */
.reference-card {
  position: relative;
}

.reference-card.selected {
  box-shadow: 0 0 0 2px #ff5f00, 0 4px 10px rgba(0,0,0,0.15);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .section-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .bulk-actions {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
  
  .bulk-action-controls {
    flex-direction: column;
  }
  
  .bulk-action-controls button {
    width: 100%;
  }
}

/* Çoklu görsel yükleme ve önizleme stilleri */
.image-preview {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 120px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  padding: 10px;
  background-color: #f9f9f9;
  justify-content: center;
  align-items: center;
}

.image-preview .preview-image {
  max-width: 120px;
  max-height: 100px;
  border-radius: 4px;
  object-fit: contain;
  border: 1px solid #eee;
  padding: 5px;
  background-color: white;
}

.no-image {
  color: #999;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

/* Mevcut görsel listesi */
.image-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
}

.image-item {
  position: relative;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 5px;
  background: white;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.image-item:hover {
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.image-item img {
  max-width: 100%;
  max-height: 100px;
  object-fit: contain;
}

.remove-image-button, 
.set-main-image-button {
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.image-item:hover .remove-image-button,
.image-item:hover .set-main-image-button {
  opacity: 1;
}

.remove-image-button {
  top: 5px;
  right: 5px;
  background-color: #ff5f00;
}

.remove-image-button svg {
  fill: white;
  width: 16px;
  height: 16px;
}

.set-main-image-button {
  bottom: 5px;
  right: 5px;
  background-color: #4CAF50;
}

.set-main-image-button svg {
  fill: white;
  width: 16px;
  height: 16px;
}

/* Çoklu dosya seçimi için gelişmiş form */
input[type="file"].file-input {
  padding: 10px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  background-color: #f9f9f9;
}

input[type="file"].file-input:hover {
  background-color: #f0f0f0;
  border-color: #aaa;
}

/* Form alanları */
.form-group label {
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
}

.image-count {
  display: inline-block;
  background-color: #ff5f00;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 10px;
  margin-left: 8px;
}

@media (max-width: 768px) {
  .image-list {
    justify-content: center;
  }
}

/* Ana görseli belirtmek için belirgin stiller */
.image-item.main-image {
  border: 2px solid #ff5f00;
  position: relative;
}

.image-item.main-image::after {
  content: "Ana Görsel";
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: rgba(255, 95, 0, 0.8);
  color: white;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 3px;
}
