.policy-page {
  max-width: 800px;
  margin: 120px auto 60px;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.policy-page h1 {
  font-size: 28px;
  color: #222;
  margin-bottom: 20px;
  border-bottom: 2px solid #ff5f00;
  padding-bottom: 10px;
}

.policy-page h2 {
  font-size: 22px;
  color: #444;
  margin: 25px 0 15px;
}

.policy-page p {
  line-height: 1.6;
  margin-bottom: 15px;
  color: #555;
}

.policy-page ul {
  margin: 15px 0;
  padding-left: 20px;
}

.policy-page li {
  margin-bottom: 8px;
  line-height: 1.6;
  color: #555;
}

.policy-page a {
  color: #ff5f00;
  text-decoration: none;
}

.policy-page a:hover {
  text-decoration: underline;
}

.policy-page .policy-header {
  margin-bottom: 30px;
  text-align: center;
}

.policy-page .policy-date {
  color: #777;
  font-size: 0.9rem;
  margin-bottom: 25px;
  font-style: italic;
}

.policy-page .policy-section {
  margin-bottom: 30px;
}

.policy-page .section-title {
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
  font-size: 18px;
}

/* Mobil görünüm */
@media (max-width: 768px) {
  .policy-page {
    margin: 90px 15px 40px;
    padding: 20px;
  }
  
  .policy-page h1 {
    font-size: 24px;
  }
  
  .policy-page h2 {
    font-size: 20px;
  }
}
