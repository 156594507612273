.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: 'Inter', sans-serif;
}

.login-container {
  width: 100%;
  max-width: 450px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  padding: 2.5rem;
  margin: 2rem;
}

.login-header {
  text-align: center;
  margin-bottom: 2rem;
}

.login-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #222;
  margin-bottom: 0.5rem;
}

.login-header h1 span {
  color: #ff5f00;
}

.login-header h2 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #666;
  margin-top: 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 0.8rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  color: #333; /* Yazılan metin rengini koyu yapıyorum */
}

.form-group input:focus {
  outline: none;
  border-color: #ff5f00;
  box-shadow: 0 0 0 2px rgba(255, 95, 0, 0.1);
  color: #333; /* Odaklandığında metin rengini belirgin şekilde koyu yap */
}

.form-group input::placeholder {
  color: #bbb;
  font-size: 0.9rem;
  font-weight: 400;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.form-group input:focus::placeholder {
  opacity: 0.7;
  transform: translateY(-2px);
}

.login-button {
  background-color: #ff5f00;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.login-button:hover {
  background-color: #e55400;
}

.login-button:disabled {
  background-color: #ffaa80;
  cursor: not-allowed;
}

.login-error {
  background-color: #ffe5e5;
  border-left: 4px solid #ff3b3b;
  color: #d10000;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  border-radius: 2px;
  margin-bottom: 1rem;
}

.login-footer {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #eee;
}

.login-footer p {
  color: #888;
  font-size: 0.8rem;
}

/* Responsive tasarım */
@media (max-width: 500px) {
  .login-container {
    padding: 1.5rem;
    margin: 1rem;
  }
  
  .login-header h1 {
    font-size: 2rem;
  }
  
  .login-button {
    padding: 0.8rem;
  }
}
