/* BlogManager Styles */
.blog-manager {
  padding: 24px;
  background-color: #f9f9f9;
  border-radius: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  color: #333333 !important;
}

.blog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 2px solid #f0f0f0;
}

.blog-header h2 {
  font-size: 1.8rem;
  color: #333;
  margin: 0;
  font-weight: 600;
  position: relative;
  padding-left: 18px;
}

.blog-header h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6px;
  background-color: #FF6B00;
  border-radius: 4px;
}

.blog-actions {
  display: flex;
  gap: 12px;
}

.primary-button, .secondary-button, .cancel-button, .save-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 18px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 120px;
  justify-content: center;
}

.primary-button {
  background-color: #FF6B00;
  color: white;
}

.primary-button:hover {
  background-color: #E05F00;
}

.secondary-button {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ddd;
}

.secondary-button:hover {
  background-color: #f9f9f9;
  border-color: #ccc;
}

.cancel-button {
  background-color: #f1f1f1;
  color: #666;
}

.cancel-button:hover {
  background-color: #e5e5e5;
}

.save-button {
  background-color: #0c9d58;
  color: white;
}

.save-button:hover {
  background-color: #0b8c4f;
}

.save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.blog-manager-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #FF6B00;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* BlogList Styles */
.blog-list-container {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.blog-filters {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #eee;
}

.search-filter {
  flex: 1;
  max-width: 400px;
  position: relative;
}

.search-filter::before {
  content: "🔍";
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #999;
}

.blog-search-input {
  width: 100%;
  padding: 12px 15px 12px 35px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
  transition: all 0.2s;
}

.blog-search-input:focus {
  outline: none;
  border-color: #FF6B00;
  box-shadow: 0 2px 8px rgba(255, 107, 0, 0.15);
}

.filter-group {
  display: flex;
  gap: 12px;
}

.category-filter, .status-filter {
  padding: 10px 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: white;
  font-size: 14px;
  min-width: 160px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
  transition: all 0.2s;
}

.category-filter:focus, .status-filter:focus {
  outline: none;
  border-color: #FF6B00;
  box-shadow: 0 2px 8px rgba(255, 107, 0, 0.15);
}

.no-posts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  text-align: center;
}

.no-posts h3 {
  margin: 20px 0 12px;
  color: #444;
  font-size: 18px;
}

.no-posts p {
  color: #777;
  max-width: 350px;
  line-height: 1.5;
}

.blog-posts-table {
  width: 100%;
}

.blog-table-header {
  display: flex;
  background-color: #f5f5f5;
  border-bottom: 2px solid #eee;
  font-weight: 600;
  color: #444;
  padding: 15px 0;
}

.blog-table-body {
  max-height: 700px;
  overflow-y: auto;
}

.blog-table-row {
  display: flex;
  border-bottom: 1px solid #eee;
  transition: all 0.2s ease;
}

.blog-table-row:hover {
  background-color: #f9f9f9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

.blog-col {
  padding: 18px 15px;
  display: flex;
  align-items: center;
}

.title-col {
  flex: 3;
  min-width: 280px;
}

.category-col {
  flex: 1;
  min-width: 120px;
}

.date-col {
  flex: 1;
  min-width: 150px;
}

.status-col {
  flex: 0.7;
  min-width: 100px;
}

.actions-col {
  flex: 0.5;
  min-width: 100px;
  justify-content: flex-end;
  gap: 10px;
}

.blog-title {
  display: flex;
  gap: 15px;
  align-items: center;
}

.blog-thumbnail {
  width: 70px;
  height: 50px;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 2px solid #fff;
}

.blog-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-info h3 {
  margin: 0 0 6px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.blog-info p {
  margin: 0;
  font-size: 13px;
  color: #777;
  line-height: 1.4;
}

.status-badge {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
}

.status-badge.published {
  background-color: #e1f5e9;
  color: #0c9d58;
  border: 1px solid rgba(12, 157, 88, 0.2);
}

.status-badge.draft {
  background-color: #f1f1f1;
  color: #777;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.edit-button, .delete-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.edit-button {
  color: #2196F3;
}

.edit-button:hover {
  background-color: rgba(33, 150, 243, 0.1);
  transform: translateY(-2px);
}

.delete-button {
  color: #F44336;
}

.delete-button:hover {
  background-color: rgba(244, 67, 54, 0.1);
  transform: translateY(-2px);
}

/* BlogEditor Styles */
.blog-editor {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #eee;
}

.editor-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
  font-weight: 600;
  position: relative;
  padding-left: 18px;
}

.editor-header h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #FF6B00;
  border-radius: 4px;
}

.editor-actions {
  display: flex;
  gap: 12px;
}

.editor-layout {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  padding: 24px;
}

.editor-main {
  min-height: 600px;
}

.editor-sidebar {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sidebar-section {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.sidebar-section h3 {
  margin: 0 0 18px;
  font-size: 16px;
  color: #444;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.sidebar-section h3::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: #FF6B00;
  border-radius: 2px;
}

/* Form Elements - Daha yüksek spesifiklik için blog-manager sınıfı ile birlikte */
.blog-manager .form-group {
  margin-bottom: 24px;
}

.blog-manager .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  color: #333333;
  font-size: 16px;
  letter-spacing: 0.3px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

.blog-manager .form-group input,
.blog-manager .form-group textarea,
.blog-manager .form-group select {
  width: 100%;
  padding: 14px 18px;
  border: 2px solid #cccccc;
  border-radius: 8px;
  font-size: 16px;
  background-color: #ffffff;
  color: #333333;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.blog-manager input,
.blog-manager textarea,
.blog-manager select {
  color: #333333 !important;
  background-color: #ffffff !important;
}

/* Tüm input/textarea placeholder stilleri */
.blog-manager input::-webkit-input-placeholder,
.blog-manager textarea::-webkit-input-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

.blog-manager input:-moz-placeholder,
.blog-manager textarea:-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

.blog-manager input::-moz-placeholder,
.blog-manager textarea::-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

.blog-manager input:-ms-input-placeholder,
.blog-manager textarea:-ms-input-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

.blog-manager input::placeholder,
.blog-manager textarea::placeholder {
  color: #666666 !important;
  opacity: 1 !important;
  font-weight: 500 !important;
}

/* Özel ID'lere sahip form alanları için placeholder stilleri */
#title::placeholder,
#slug::placeholder,
#meta-title::placeholder,
#keywords::placeholder,
#excerpt::placeholder,
#content-editor::placeholder,
#meta-description::placeholder,
#category-name::placeholder,
#category-description::placeholder {
  color: #000000 !important;
  opacity: 0.75 !important; 
  font-weight: 500 !important;
  font-style: italic !important;
}

/* Kesinlikle gösterilmesi gereken placeholder'lar için */
input[id="title"]::-webkit-input-placeholder,
input[id="slug"]::-webkit-input-placeholder,
input[id="meta-title"]::-webkit-input-placeholder,
input[id="keywords"]::-webkit-input-placeholder {
  color: #000000 !important;
  opacity: 0.75 !important;
  font-weight: 600 !important;
  text-shadow: none !important;
  -webkit-text-fill-color: #000000 !important;
}

/* Girdi alanları için kontrastlı arka plan rengi */
.blog-editor input, 
.blog-editor textarea, 
.blog-editor select,
.category-manager input,
.category-manager textarea,
.category-manager select {
  background-color: #f8f8f8 !important;
  border: 2px solid #dddddd !important;
  color: #000000 !important;
  font-weight: 500 !important;
}

/* Placeholder stilleri için doğrudan eleman seçicileri */
#title::placeholder,
#slug::placeholder,
#meta-title::placeholder,
#keywords::placeholder,
#excerpt::placeholder,
#content-editor::placeholder,
#meta-description::placeholder,
#category-name::placeholder,
#category-description::placeholder {
  color: #333333 !important;
  opacity: 0.7 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  font-style: italic !important;
}

/* Webkit tarayıcılar için (Chrome, Safari) */
#title::-webkit-input-placeholder,
#slug::-webkit-input-placeholder,
#meta-title::-webkit-input-placeholder,
#keywords::-webkit-input-placeholder,
#excerpt::-webkit-input-placeholder,
#content-editor::-webkit-input-placeholder,
#meta-description::-webkit-input-placeholder,
#category-name::-webkit-input-placeholder,
#category-description::-webkit-input-placeholder {
  color: #333333 !important;
  opacity: 0.7 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  font-style: italic !important;
}

/* Firefox için */
#title::-moz-placeholder,
#slug::-moz-placeholder,
#meta-title::-moz-placeholder,
#keywords::-moz-placeholder,
#excerpt::-moz-placeholder,
#content-editor::-moz-placeholder,
#meta-description::-moz-placeholder,
#category-name::-moz-placeholder,
#category-description::-moz-placeholder {
  color: #333333 !important;
  opacity: 0.7 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  font-style: italic !important;
}

/* Form elemanları üzerindeki focus efektleri */
#title:focus,
#slug:focus,
#meta-title:focus,
#keywords:focus,
#excerpt:focus,
#content-editor:focus,
#meta-description:focus,
#category-name:focus,
#category-description:focus {
  border-color: #FF6B00 !important;
  box-shadow: 0 0 0 3px rgba(255, 107, 0, 0.2) !important;
  outline: none !important;
}

.blog-manager .form-group input:focus::placeholder,
.blog-manager .form-group textarea:focus::placeholder {
  color: #bbbbbb;
}

.blog-manager .form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%23333' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 40px;
}

.blog-manager .form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.blog-manager .form-group small {
  display: block;
  margin-top: 8px;
  color: #666666;
  font-size: 13px;
  line-height: 1.4;
}

/* Editor alanındaki özel stiller */
.blog-editor .form-group label {
  color: #222222;
  font-size: 16px;
  font-weight: 700;
}

.blog-editor .form-group input::placeholder,
.blog-editor .form-group textarea::placeholder {
  color: #888888;
  font-weight: 400;
}

/* Kategori yöneticisindeki özel stiller */
.category-manager .form-group label {
  color: #222222;
  font-size: 16px;
  font-weight: 700;
}

.category-manager .form-group input::placeholder,
.category-manager .form-group textarea::placeholder {
  color: #888888;
  font-weight: 400;
}

/* Char count için özel stil */
.char-count {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 600;
  color: #666666;
  text-align: right;
}

/* CategoryManager Styles */
.category-manager {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #eee;
}

.category-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
  font-weight: 600;
  position: relative;
  padding-left: 18px;
}

.category-header h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #FF6B00;
  border-radius: 4px;
}

.category-content {
  padding: 24px;
}

.category-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.category-form-section, .category-list-section {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.category-form-section h3, .category-list-section h3 {
  margin: 0 0 18px;
  font-size: 16px;
  color: #444;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.category-form-section h3::after, .category-list-section h3::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: #FF6B00;
  border-radius: 2px;
}

.category-form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.no-categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  text-align: center;
  color: #777;
}

.categories-table {
  width: 100%;
}

.category-table-header {
  display: flex;
  background-color: #f1f1f1;
  border-bottom: 2px solid #eee;
  font-weight: 600;
  color: #444;
  padding: 12px 0;
}

.category-row {
  display: flex;
  border-bottom: 1px solid #eee;
  transition: all 0.2s ease;
}

.category-row:hover {
  background-color: #f9f9f9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

.category-col {
  padding: 15px 12px;
  display: flex;
  align-items: center;
}

.name-col {
  flex: 2;
  min-width: 200px;
  flex-direction: column;
  align-items: flex-start;
}

.name-col span {
  margin-bottom: 4px;
  font-weight: 500;
  color: #333;
}

.name-col small {
  font-size: 12px;
  color: #777;
  line-height: 1.4;
}

.slug-col {
  flex: 1.5;
  min-width: 150px;
  font-family: monospace;
  color: #555;
  font-size: 13px;
  background-color: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0 10px;
}

/* Toggle Switch Stillleri */
.toggle-switch {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

input[type="checkbox"] {
  display: none;
}

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-inner {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  background-color: #cccccc;
  border-radius: 24px;
  transition: all 0.3s;
  margin-right: 10px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.toggle-inner:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

input:checked + .toggle-label .toggle-inner {
  background-color: #FF6B00;
}

input:checked + .toggle-label .toggle-inner:before {
  transform: translateX(24px);
}

.toggle-switch-label {
  font-size: 15px;
  color: #444444;
  font-weight: 500;
}

/* Öne Çıkan Görsel Stilleri */
.featured-image-container {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  background-color: #f5f5f5;
  cursor: pointer;
  border: 3px dashed #dddddd;
  transition: all 0.3s ease;
}

.featured-image-container:hover {
  border-color: #FF6B00;
  background-color: #fff6f0;
  box-shadow: 0 4px 12px rgba(255, 107, 0, 0.1);
  transform: translateY(-2px);
}

.featured-image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featured-image-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px;
}

.featured-image-placeholder svg {
  margin-bottom: 16px;
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
}

.featured-image-placeholder p {
  margin-top: 10px;
  color: #666666;
  font-size: 15px;
  font-weight: 500;
}

/* SEO Önizleme Stilleri */
.seo-preview {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
  margin-top: 10px;
}

.search-preview {
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  border: 1px solid #eeeeee;
}

.search-title {
  color: #1a0dab;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-url {
  color: #006621;
  font-size: 14px;
  margin-bottom: 8px;
  font-family: monospace;
}

.search-description {
  color: #545454;
  font-size: 14px;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .editor-layout {
    grid-template-columns: 1fr;
  }
  
  .category-layout {
    grid-template-columns: 1fr;
  }
  
  .blog-filters {
    flex-direction: column;
    gap: 15px;
  }
  
  .search-filter {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .blog-table-row {
    flex-wrap: wrap;
  }
  
  .title-col {
    flex: 1 0 100%;
  }
  
  .date-col {
    display: none;
  }
  
  .blog-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .blog-actions {
    width: 100%;
  }
  
  .primary-button, .secondary-button {
    flex: 1;
    justify-content: center;
  }
}
