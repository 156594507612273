.blog-detail-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

/* Header */
.blog-detail-header {
  position: relative;
  color: #fff;
  padding: 0;
  overflow: hidden;
}

.blog-detail-featured-image {
  height: 500px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.featured-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
}

.blog-detail-header-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 40px;
  z-index: 2;
  max-width: 900px;
}

.blog-detail-meta {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.blog-detail-category {
  background-color: #ff5f00;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.blog-detail-category:hover {
  background-color: #e85500;
}

.blog-detail-date {
  font-size: 0.9rem;
  opacity: 0.9;
}

.blog-detail-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1.2;
}

.blog-detail-excerpt {
  font-size: 1.2rem;
  opacity: 0.9;
  margin-bottom: 0;
  max-width: 800px;
}

/* Content */
.blog-detail-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 60px 20px;
  flex-grow: 1;
}

.blog-detail-content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
}

.blog-detail-content h2 {
  font-size: 2rem;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #222;
}

.blog-detail-content h3 {
  font-size: 1.6rem;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #333;
}

.blog-detail-content p {
  margin-bottom: 20px;
}

.blog-detail-content a {
  color: #ff5f00;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 95, 0, 0.3);
  transition: all 0.3s ease;
}

.blog-detail-content a:hover {
  color: #e85500;
  border-bottom-color: #e85500;
}

.blog-detail-content ul, 
.blog-detail-content ol {
  margin-bottom: 20px;
  padding-left: 20px;
}

.blog-detail-content li {
  margin-bottom: 10px;
}

.blog-detail-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 30px 0;
}

.blog-detail-content blockquote {
  border-left: 4px solid #ff5f00;
  padding-left: 20px;
  margin-left: 0;
  font-style: italic;
  color: #555;
}

.blog-detail-content code {
  background-color: #f0f0f0;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: monospace;
}

.blog-detail-content pre {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 8px;
  overflow-x: auto;
  margin: 20px 0;
}

.blog-detail-content pre code {
  background-color: transparent;
  padding: 0;
}

/* Related Posts */
.related-posts-section {
  background-color: #f8f8f8;
  padding: 60px 0;
  margin-top: 40px;
  width: 100%;
}

.related-posts-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
}

.related-posts-title {
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: #333;
  text-align: center;
}

.related-posts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.related-post-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.related-post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.related-post-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.related-post-image {
  height: 150px;
  overflow: hidden;
}

.related-post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.related-post-card:hover .related-post-image img {
  transform: scale(1.05);
}

.related-post-content {
  padding: 15px;
}

.related-post-title {
  margin: 0 0 10px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  line-height: 1.4;
}

.related-post-date {
  font-size: 0.8rem;
  color: #888;
}

/* Navigation */
.blog-detail-navigation {
  margin-top: 60px;
  text-align: center;
}

.back-to-blog {
  display: inline-flex;
  align-items: center;
  color: #ff5f00;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 20px;
  border: 2px solid #ff5f00;
  border-radius: 30px;
  transition: all 0.3s ease;
}

.back-to-blog:hover {
  background-color: #ff5f00;
  color: white;
}

/* Loading */
.blog-detail-loading {
  text-align: center;
  padding: 100px 0;
  color: #666;
  flex-grow: 1;
}

.spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 95, 0, 0.3);
  border-radius: 50%;
  border-top-color: #ff5f00;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Not Found */
.blog-detail-not-found {
  text-align: center;
  padding: 100px 20px;
  color: #333;
  flex-grow: 1;
}

.blog-detail-not-found h2 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.blog-detail-not-found p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 30px;
}

/* Responsive */
@media (max-width: 1024px) {
  .blog-detail-title {
    font-size: 2.5rem;
  }
  
  .blog-detail-featured-image {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .blog-detail-header-content {
    padding: 30px 20px;
  }
  
  .blog-detail-title {
    font-size: 2rem;
  }
  
  .blog-detail-excerpt {
    font-size: 1.1rem;
  }
  
  .blog-detail-featured-image {
    height: 350px;
  }
  
  .blog-detail-container {
    padding: 40px 20px;
  }
  
  .related-posts-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 576px) {
  .blog-detail-title {
    font-size: 1.8rem;
  }
  
  .blog-detail-featured-image {
    height: 300px;
  }
  
  .blog-detail-content {
    font-size: 1rem;
  }
  
  .related-posts-grid {
    grid-template-columns: 1fr;
  }
}
